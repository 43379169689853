.no-data-found1 {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 114px;
    border-radius: 6px;
}
.status-badge {
    padding: 3px 10px;
    font-weight: 600;
    border-radius: 6px;
    color: #000;
    font-size: 14px;

    &.active {
        background-color: #ecf7ec;
        color: #36B652;
    }

    &.cancelled {
        background-color: #f7dada;
        color: #c91616;
    }
    &.pending {
        background-color: #fff2d7;
        color: #dd840f;
    }
    &.expired {
        background-color: #e0e0e0;
        color: #464545;
    }
    &.trial {
        background-color: #e6e6fc;
        color: #0a54f3;
    }
}
.component-loader {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;
    min-height: 200px;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../../../assets/images/ailoader.gif');
        background-size: contain;

    }
}

.css-1nmdiq5-menu{
    z-index: 10;
}

.remove-loader-position{
    position: unset;
}


.table-container-sub {
    border-radius: 10px;
    border-color: #F1F1F1;
    padding: 8px;
    margin-bottom: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
    // min-height: 318px;
}