@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap');
@import "../css/variables.scss";

body {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.css-qbdosj-Input {
    visibility: visible;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: inline-grid;
    grid-area: 1/1/2/3;
    grid-template-columns: 0 min-content;
    margin: 2px;
    padding-bottom: 2px;
    padding-top: 2px;
    color: hsl(0, 0%, 20%);
    box-sizing: border-box;
}
 .css-qbdosj-Input {
        visibility: visible;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1/1/2/3;
        grid-template-columns: 0 min-content;
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        color: hsl(0, 0%, 20%);
        box-sizing: border-box;
       // height: 38px;
        font-weight: 50;
         height: fit-content;
    }
    .no-data-found {
        width: 100%;
        height: 55vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        padding: 105px;
        border-radius: 6px;
    }

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-28 {
    font-size: 28px;
}

.heading-1 {
    font-size: 29px;
    font-weight: 500;
}

.font-bold {
    font-weight: $font-bold;
}

.font-medium {
    font-weight: $font-medium;
}

.font-light {
    font-weight: $font-light;
}

.font-regular {
    font-weight: $font-regular;
}

a {
    text-decoration: none;
    transition: 0.4s;

    &:hover {
        color: $color-secondary !important;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.text-brand {
    color: $color-primary;
}
.bg-brand{background-color: $color-primary;}
.btn-brand-1 {
    background-color: $color-primary;
    color: #fff;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    padding: 7px 27px;
    line-height: 25px;

    &:hover {
        background-color: $color-secondary;
        color: #fff !important;
    }
}

.btn-brand-light {
    // background-color: $color-primary-light;
    background-color: #dedede;
    color: $color-primary;
    font-weight: 500;
    font-size: 15px;
    border: 0;
    height: 40px;

    &:hover {
        background-color: $color-primary;
        color: #fff !important;
    }
}

.btn-brand-outline-light {
    @extend .btn-brand-light;
    border: 1px solid $color-primary;
}

.checkbox-danger {
    .form-check-input:checked {
        background-color: #a50505 !important;
        border-color: #a50505 !important;
    }
}

.checkbox-success {
    .form-check-input:checked {
        background-color: #098807 !important;
        border-color: #098807 !important;
    }
}

.checkbox-warning {
    .form-check-input:checked {
        background-color: #db6409 !important;
        border-color: #db6409 !important;
    }
}

.labe-text-dark {
    .form-check-label {
        color: #000 !important;
    }
}
.css-13cymwt-control{
    // height: 48px;
    border-color: #dee2e6 !important;
 }
// left menu css

.layout-veritcle-menu {
    display: flex;
    min-height: 100vh;

    .verticle-menu {
        background-color: #fff;
        min-width: 250px;
        height: calc(100vh - 70px);
        position: sticky;
        top: 71px;
        padding-top: 35px;
        z-index: 4;

        // &::before {
        //     content: "";
        //     width: 50px;
        //     height: 50px;
        //     background-image: url('../../assets/images/page-corner.svg');
        //     background-size: contain;
        //     position: fixed;
        //     left: 218px;
        //     top: 79.5px;
        //     background-repeat: no-repeat;
        // }

        .menu-list {
            .menu-item {
                transition: 0.3s;
                display: flex;
                gap: 10px;
                align-items: center;
                padding: 7px 16px;
                height: 44px;
                border-radius: 22px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;
                color: $color-primary;

                &:hover,
                &:focus {
                    opacity: 1;
                    background-color: $color-primary-light;
                }

                .menu-icon {
                    width: 22px;
                    -webkit-filter: saturate(0%) grayscale(100%) brightness(69%) contrast(1000%);
                }
            }

            .dropdown-toggle {
                @extend .menu-item;
                border: 0;
                background-color: transparent;


                &::after {
                    border: 0;
                }

                &.show {
                    background-color: $color-primary-light;
                    opacity: 1;
                }
            }

            .dropend {
                text-align: center;
            }

            .dropdown-menu {
                left: -3px !important;
                border-radius: 0 6px 6px 0;
                background-color: $color-primary;

                .dropdown-item {
                    color: #fff;
                    font-size: 14px;
                    padding: 9px 13px;
                    margin-bottom: 3px;

                    &:hover {
                        background-color: #ffffff2b;
                        color: #ffffff !important;
                    }
                }
            }
        }

        .tooltip-inner {
            background-color: #343434 !important;
        }

        .version-badge{          
            color: $color-primary;
            font-size: 12px;
            border-radius: 8px;
            margin: 0 auto;
            text-align: center;
            padding: 4px 10px;
            margin-bottom: 18px;
        }
    }

    .app-body {
        width: calc(100% - 250px);
        padding-top: 100px;
    }
}

// header 

.site-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 112;

    .header-nav {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .header-nav {
        .navbar {
            padding: 0;
        }
    }

    .header-nav {
        background-color: #fff;

        .v-devider {
            display: inline-block;
            width: 1px;
            background: #525252;
            height: 20px;
            margin: 0 15px;
        }
    }

    .profile-dd {

        .dropdown-menu {
            min-width: 200px;
            top: 68px;
            border: 0;
            box-shadow: 0px 3px 10px #0000002b;

            .dropdown-item:hover a,
            .dropdown-item:hover .icon {
                color: #000 !important;
            }
        }

        .btn {
            background-color: #F8F8F8;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 0;

            &::after {
                border: 0;
            }

            border-radius: 30px;
            padding: 7px 10px;
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: -4px;
            color: #4b4b4b;
        }
    }

    .help-btn {
        background-color: #F8F8FF;
        font-size: 14px;
        color: $color-primary;
        font-weight: $font-medium;
        height: 48px;
        padding: 8px 15px;
    }
}

.back-arrow {
    position: relative;
    z-index: 111;
}
.css-16xfy0z-control{
    min-height: 47px !important;
}
// App Body 
.content-card{
    padding: 15px;
    background-color: #fff;
    border: 0;
}
.component-loader {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;
    min-height: 200px;

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../assets/images/ailoader.gif');
        background-size: contain;

    }
}
.component-loader1 {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;
    min-height: 500px;

    .loader-icon {
        width: 120px;
        height: 250px;
    }

    .loader {
        width: 80px;
        height: 88px;
        background-image: url('../../assets/images/ailoader.gif');
        background-size: contain;

    }
}
.component-loader2 {
    background: #ffffffd4;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2;
    min-height: 350px;

    .loader-icon {
        width: 120px;
        height: 250px;
    }

    .loader {
        width: 80px;
        height: 88px;
        background-image: url('../../assets/images/ailoader.gif');
        background-size: contain;

    }
}
.app-body {
    background: #f5f5f5;
    padding-left: 15px;
    padding-right: 15px;
    overflow-x: hidden;
}

.page-title {
    font-size: 24px;
    font-weight: $font-bold;
}

.tab-style-1 {
    background-color: #fff;
    width: max-content;
    border-radius: 6px;

    .nav-link {
        color: #000;
        font-size: 14px;
        padding: 11px 25px;
        background-color: #f0eeee;

        &.active {
            background-color: $color-primary;
            color: #fff; 
            
            &:hover {
                color: #fff !important; 
            }
        }
    }
}


.form-control:focus,
.form-select:focus {
    box-shadow: none;
    border-color: #ddd;
}

.filter-option {
    height: 40px;
}

.table-search-box {
    .form-control {
        height: 40px;
        border: 0;
        padding-left: 0;

        &::placeholder {
            color: #8c8989;
        }
    }

    .input-group-text {
        background-color: #fff;
        border: 0;
        height: 40px;
    }

    .icon {
        width: 20px;
        height: 20px;
        color: #676767;
    }
}

.table-card {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    padding: 12px;

    .table-heading {
        font-size: 18px;
        margin-bottom: 0;
    }

    .table-container {
        border-radius: 10px;
        border-color: #F1F1F1;
        padding: 8px;
        margin-bottom: 12px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
        min-height: 318px;
    }

    .table-style {
        margin-bottom: 0;

        thead th {
            font-size: 12px;
            font-weight: $font-medium;
            color: #667085;
            border: 0;
            white-space: nowrap;
        }

        tbody tr td {
            font-size: 14px;
            font-weight: 500;
            color: #101828;
            border: 0;
        }

        .action-col {
            text-align: center;
        }

        .action-btns {
            text-align: center;
            display: flex;
            gap: 5px;
            justify-content: center;

            .action-btn {
                padding: 5px 7px;
                background-color: #F5F5F5;
                color: #333;
                border: 0;
                transition: 0.3s;
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 600;
                gap: 6px;

                .icon {
                    width: 18px;
                    height: 18px;
                }

                &:hover {
                    box-shadow: 0px 0px 10px #0000004b;
                }
            }

            .btn-delete {
                background-color: #FDECEC;
                color: #D53727
            }

            .btn-view {
                background-color: #dedede;
                color: #000;
                border-radius: 15px;
            }

            .btn-trial {
                background-color: #e6e6fc;
                color: #0a54f3;
                border-radius: 15px;
            }
        }

    }

    .pagination {
        margin-bottom: 0;
        justify-content: end;

        .page-item {
            .page-link {
                padding: 4px 12px;
                font-size: 14px;
                color: #333;
            }

            &.active .page-link {
                background-color: $color-primary;
                color: #fff;
                border-color: #fff;
            }
        }
    }
}

.tab-style-2 {
    gap: 15px;
    flex-wrap: nowrap;

    .nav-item {
        width: 100%;
    }

    .nav-link {
        background-color: #fff;
        width: 100%;
        padding: 15px;
        font-size: 18px;
        color: #000;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid transparent;

        &.active {
            background-color: #E2EFFF;
            color: #000;
            border-color: #1977F3;
        }
    }
}

.name-avtart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: $color-primary-light;
    color: $color-primary;
    font-weight: $font-bold;
    font-size: 20px;
}

.status-badge {
    padding: 5px 14px;
    font-weight: $font-medium;
    border-radius: 6px;
    color: #000;
    font-size: 14px;

    &.active {
        background-color: #ECFFEC;
        color: #36B652;
    }

    &.deactive {
        background-color: #F3F3F3;
        color: #333;
    }
    &.pending {
        background-color: #fff2d7;
        color: #dd840f;
    }
    &.expired {
        background-color: #e0e0e0;
        color: #464545;
    }
    &.trial {
        background-color: #e6e6fc;
        color: #0a54f3;
    }
    &.cancelled {
        background-color: #f7dada;
        color: #c91616;
    }
    &.payment_due {
        background-color: #f7dada;
        color: #632705;
    }
}

.offcanvas {
    border-radius: 5px 0 0 5px;
    min-width: 590px;

    .offcanvas-title {
        font-size: 18px;
        font-weight: $font-medium;
    }

    .offcanvas-header {
        border-bottom: 1px solid #e8e6e6;
    }
}

.status-badge.active-2 {
    color: #92935E;
    background-color: #FBFAE9;
}

.perce-adon {
    .form-control{border-right: 0;}
    .input-group-text {
        background-color: #fff;
        border-left: 0;
       
        span {
        display: inline-flex;
        background: #655f94;
        color: #fff;
        font-size: 11px;
        width: 28px;
        height: 28px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 5px;
        font-weight: 600;
    }
}
}

.form-style {
    display: contents;

    .form-floating>label {
        font-size: 14px;
        padding: 7px 13px;
        color: #676767;
    }

    .form-floating .form-control,
    .form-floating .form-select {
        height: 38px;
        min-height: 38px;
        padding: 6px 12px !important;
        font-size: 15px;
    }

    .form-floating>.form-control:focus~label,
    .form-floating>.form-control:not(:placeholder-shown)~label,
    .form-floating>.form-control-plaintext~label,
    .form-floating>.form-select~label {
        top: 0;
        width: fit-content;
        background: #fff;
        opacity: 1;
        height: 20px;
        padding: 0px 4px;
        left: 5px;
        font-size: 12px;
    }

    .form-floating>.form-select~label {
        left: 8px;
    }

    .form-select:focus {
        box-shadow: none;
        border-color: #ddd;
    }
}
.form-style.normal{
    .form-label{font-size: 15px;color: #212123;    margin-bottom: 4px;}
    .form-control, .form-select{height: 48px;}
}

.form-check {
    .form-check-label {
        font-size: 16px;
        color: #6D6D6D;
    }

    .form-check-input:checked {
        background-color: $color-primary;
        border-color: $color-primary;
    }
}

.upload-btn-container {
    position: relative;

    img {
        width: 200px;
        height: auto;
    }

    input {
        display: none;
    }

    label {
        width: 100%;
        height: 55px;
        background: $color-primary-light;
        border: 1px dashed $color-primary;
        color: $color-primary;
        font-size: 14px;
        text-transform: capitalize;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

    }

    .tb-img-view {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.chat-box {
    height: calc(100vh - 410px);
    overflow-y: auto;

    .chat-card {
        background-color: #e9e9eb !important;
    }

    .chat-corner-left::before {
        content: "";
        width: 24px;
        height: 38px;
        position: absolute;
        left: -5px;
        background-image: url('../images/chat-corner-left.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -15px;
    }

    .chat-corner-right::before {
        content: "";
        width: 24px;
        height: 38px;
        position: absolute;
        right: -5px;
        background-image: url('../images/chat-corner-right.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        bottom: -15px;
    }
}

.loader2 {
    position: absolute;
    left: 44%;
    top: 38vh;
    height: 150px !important;
    width: 150px !important;
}

.text-reconnect{
    color: #dd840f;
}
.dropdown-item{
    font-size: 14px;
}

.dashboard-loader {
    position: fixed; 
    top: 0;
    left: 50px;
    width: 100vw; 
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    z-index: 2; 

    .loader-icon {
        width: 120px;
        height: 120px;
    }

    .loader {
        width: 80px;
        height: 80px;
        background-image: url('../../assets/images/dashboard-loader.gif');
        background-size: contain;
    }
}

.campaign-main-div {
    display: flex;
    gap: 12px;
    margin-bottom: 10px;

    .connect-line {
        background-color: #B8B8B8;
        width: 1px;
        height: 100%;
        margin: 0 auto;
    }

    .campaign-icon {
        background-color: #466BF3;
        border-radius: 50px;
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .campaign-div {
        background-color: #F8F9FB;
        border-radius: 10px;
        padding: 15px;
        width: 100%;
    }
}




// responsive css
@media (max-width: 767px) {}

