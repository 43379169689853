.page-integration {
    .inetegration-card {
        margin-bottom: 15px;
        border: 0;
        border-radius: 12px;
        width: 100%;
        flex-direction: unset;
    }

    .integration-table {
        width: 100%;
       
        tr:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }

        tr td{
            padding: 15px 15px;
        }
    }
  

    
     
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 555px;
    outline: none;
}

.css-1fdsijx-ValueContainer {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: grid;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    padding: 2px 8px;
    box-sizing: border-box;
    min-height: 30px;
}

.action-btns {
    text-align: center;
    display: flex;
    gap: 5px;
    justify-content: center;

    .action-btn {
        padding: 5px 7px;
        background-color: #F5F5F5;
        color: #333;
        border: 0;
        transition: 0.3s;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        gap: 6px;

        .icon {
            width: 18px;
            height: 18px;
        }

        &:hover {
            box-shadow: 0px 0px 10px #0000004b;
        }
    }

    .btn-delete {
        background-color: #FDECEC;
        color: #D53727
    }

    .btn-view {
        background-color: #ECFDF3;
        color: #027A48;
        border-radius: 15px;
    }
}

.custom-placeholder::placeholder {
  padding-left: 5px;
  font-size: 15px; 
}


.password-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .password-container span {
    display: flex;
  }
  
  .icon {
    cursor: pointer;
  }


  .unset-center{
    justify-content: unset;
  }
